<template>
  <a-drawer
      :zIndex="1000"
      :title="dialog.type === 'add'? 'Create new Model' : 'Edit Model'"
      :width="720"
      @close="onClose"
      :visible="dialog.show"
      :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
    >
      <bh-loading :show="loading" />

      <a-form  layout="vertical" >


              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-item label="Name" required>
                    <a-input v-model="newModel.name" placeholder="Model Name" >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="Unit Type" required>
                    <a-cascader expandTrigger="hover" @change="selectType" v-model="newModel.unitTypeArray" :options="unitTypes" placeholder="Select Unit Type" />
                  </a-form-item>
                </a-col>

                <a-col :span="9">
                    <a-form-item label="Status">
                      <a-select v-model="newModel.status">
                          <a-select-option value="published">Published</a-select-option>
                          <a-select-option value="draft">Draft</a-select-option>
                      </a-select>
                    </a-form-item>
                </a-col>
              </a-row>

              <hr />

              <a-row>
                <a-col :span="24">
                  <h5>Images</h5>
                </a-col>
              </a-row>

              <a-row class="mt-3">
                <a-col :span="6">
                  <a-form-item label="Main Image" required >
                    <image-box-selector :key="newTime" v-model="newModel.image" type="images"/>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="Front View if required" >
                    <image-box-selector :key="newTime"  v-model="newModel.frontView" type="images"/>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="Rear View if required" >
                    <image-box-selector :key="newTime"  v-model="newModel.backView" type="images"/>
                  </a-form-item>
                </a-col>
              </a-row>

              <div
                :style="{
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '100%',
                  borderTop: '1px solid #e9e9e9',
                  padding: '10px 16px',
                  background: '#fff',
                  textAlign: 'right',
                }"
              >
                <div class="dF jSB">
                  <div>
                    <a-popover title="Title" trigger="click" v-model="deletePopup">
                      <div slot="content">
                          Are you sure? This will delete this Model
                          <div class="mt-3">
                          <a-button :style="{marginRight: '8px'}" @click="deletePopup = false" :disabled="deleting">
                              No
                          </a-button>
                          <a-button  @click="confirmDelete" type="danger" :disabled="deleting" :icon="deleting? 'loading' : ''">
                              Yes
                          </a-button>
                          </div>
                      </div>
                      <a-button :disabled="deleting" @click="deletePopup = true" v-if="dialog.type === 'edit'" type="danger" ghost icon="delete">Delete Model</a-button>
                  </a-popover>

                  </div>
                  <div>
                    <a-button :style="{marginRight: '8px'}" @click="onClose">
                      Cancel
                    </a-button>
                    <a-button  type="primary" class="btn-purple" @click="submit">{{dialog.type === 'add' ? 'Create' : 'Update'}} Model</a-button>
                  </div>
                </div>


              </div>

      </a-form>

    </a-drawer>
</template>

<script>
  import {markerIcon} from 'bh-mod'
  import bhLoading from 'bh-mod/components/common/Loading'
  import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
  export default {
    components:{bhLoading,ImageBoxSelector},
    data() {
      return {
        loading:false,
        deleting:false,
        deletePopup:false,
        newTime:'SDFXCV####XDVXCVX',
        newModel:{
          name:'',
          unitType:'',
          unitTypeArray:[],
          status:'published',
          image:'',
          frontView:'',
          backView:'',
        }
      }
    },
    watch:{
      dialog:{
        handler(val){
          console.log("adding new models")
          if (!val.show){
            this.newModel = {
              name:'',
              unitType:'',
              unitTypeArray:[],
              status:'published',
              image:'',
              frontView:'',
              backView:'',
            }
          } else if (val.type === 'edit'){
            console.log("editting models")
            let model = JSON.parse(JSON.stringify(this.$store.state.models.editModel))
            console.log('MODEL INFO', model)
            if (model.unitType){
              model.unitTypeArray = [model.unitType.name.trim().toLowerCase(),model.unitType.id ]
            }
            this.newModel = model
          }
          this.newTime = Date.now()
        },
        deep:true
      }
    },
    computed:{
        instance(){
          return this.$store.state.instance
        },
        appData(){
          return this.$store.state.appData
        },
        getTitle(){
          return `Create a new `
        },
        dialog(){
          return this.$store.state.models.modelDialog
        },
        unitTypes(){
          // let types = this.$store.state.models.unitTypes
          // let result = {}
          // types.forEach(x => {
          //   let name = x.name.trim().toLowerCase()
          //   if (!result[name]){
          //     result[name] = {
          //       label:x.name,
          //       value:name,
          //       children:[]
          //     }
          //   }
          //   result[name].children.push({label:x.size,value:x.id})
          // })

          // return Object.values(result)

        }
    },
    methods: {
       confirmDelete(){
        this.deleting = true
        this.$api.delete(`/units/${this.instance.id}/unitgroups/${this.newModel.id}`)
            .then( ({data}) => this.onClose({type:'model---delete', data})).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
        this.deletePopup = false
        this.deleting = false
        this.loading = false
      },
      submit(){
        if (this.dialog.type === 'add') return this.createModel()
        return this.updateModel()
      },
      updateModel(){
        if (!this.newModel.name || !this.newModel.unitType || !this.newModel.image) this.$message.error('Fields are missing')
        this.$api.put(`/units/${this.instance.id}/unitgroups/${this.newModel.id}`, this.newModel).then( ({data}) => {
          if (data.id){
            this.onClose({type:'model--edit',data})
          }
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
      },
      createModel(){
        if (!this.newModel.name || !this.newModel.unitType || !this.newModel.image) this.$message.error('Fields are missing')
        console.log("model", this.newModel)
        this.$api.post(`/units/${this.instance.id}/unitgroups`, this.newModel).then( ({data}) => {
          if (data.id){
            this.onClose({type:'model--added',data})
          }
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
      },
      selectType(e){
        console.log('EEEEEEEEEE', e)
        this.newModel.unitType = e[1]
      },
      onClose({type='',data={}}) {
          this.$store.dispatch('closeDialog',{type,data})
      },
    },
  };
</script>
