<template>

    <div class="w-full" style="background-color: var(--off-white-dark)">
        
        <ElevationDrawer />
        <ModelDrawer />
        <UnitTypeDrawer />
        <CustomFieldDrawer />
        
        <div class="dF jE pb-4">
            <transition name="rightToLeft" mode="out-in">
                <a-button v-if="showingModel.name" icon="edit" class="btn-purple" :key="showingModel.name" type="primary"  @click="$store.commit('SHOW_EDIT_MODEL',showingModel)" >Edit {{showingModel.name}}</a-button>
            </transition>
            
        </div>

        <div class="grid-elevations" v-if="showingModel.name">
            <template v-if="showingModel.units && showingModel.units.length">
                <ElevationItem v-for="elev in showingModel.units" :key="elev.id + $store.state.models.updatedKey" :elev="elev" @click.native="editElevation(elev)" />
            </template>
            <AddElevationItem @click.native="addElevation" />
        </div>
        <!-- <AppFooter /> -->
    </div>

</template>

<script>
    import {getBase64, deleteProp} from 'bh-mod'
    import ElevationItem from '@/components/models/ElevationItem'
    import AddElevationItem from '@/components/models/AddElevationItem'
    import ElevationDrawer from '@/components/models/ElevationDrawer'
    import CustomFieldDrawer from '@/components/models/CustomFieldDrawer'
    import ModelDrawer from '@/components/models/ModelDrawer'
    import UnitTypeDrawer from '@/components/models/UnitTypeDrawer'
    import AppFooter from 'bh-mod/components/common/AppFooter'
    export default {
        components:{
            ElevationItem,AddElevationItem,ElevationDrawer,ModelDrawer,UnitTypeDrawer,AppFooter,CustomFieldDrawer
        },
        data() {
            return {
                filter:'all',
                // newUser:true,
                obj:{a:1,b:1}
                
            }
        },
        watch:{
            currentModel(val){
                this.filter = 'all'
            },
            
        },
        computed: {
            instance() {
                return this.$store.state.instance
            },
            currentElevations(){
                return []
            },
            showingModel(){
                return this.$store.state.models.showingModel
            },
            intro() {
                return localStorage.intro
            }
        },
        methods:{
            editElevation(elev){
                this.$store.commit('SHOW_EDIT_ELEVATION',elev)
            },
            addElevation(){
                this.$store.commit('SHOW_ADD_ELEVATION')
            },
            showIntroPage() {
                localStorage.setItem('intro', 'seen')
                this.$store.commit('INTRO_PAGE')
            }
        },
        created() {
            
        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
    .grid-elevations{
        display:grid;
        grid-gap:25px;
        grid-template-columns: repeat( 4, minmax(250px, 1fr) );
    }
    .modelOption {
        height: 50px;
        width: 230px;
        display:flex;
        align-items: center;

    }
    .modelOption:hover {
        background-color: var(--light-gray);
    }
</style>
