<template>
        <a-card class="cursor-pointer dF aC jC add-elevation bg-offwhite-light" hoverable>
            <div class="dF fC aC jC f1">
                <a-icon type="plus" class="p-3 bg-purple text-white rounded-full" />
                <div class="mt-3 text-purple">Add Elevation</div>
            </div>
        </a-card>
</template>

<script>
export default {

}
</script>

<style scoped>
   .add-elevation{
       border:1px dashed var(--med-gray);
   }
</style>