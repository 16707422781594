<template>
  <a-drawer
      :zIndex="1000"
      :title="dialog.type === 'add'? 'Create Model Types' : 'Edit your Unit Types'"
      :width="720"
      @close="onClose"
      :closable="dialog.type === 'add'"
      :visible="dialog.show"
      :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
    >
      <bh-loading :show="loading" />

      <a-form  layout="vertical" >
         <template v-if="dialog.type === 'edit'">

                  <a-row :gutter="16" v-for="(type,typeI) in editTypes" :key="`${typeI}UnitTypes`">
                    <a-col :span="12">
                      <a-form-item label="Unit Type">
                          <a-input v-model="type.name" placeholder="Singles, Towns, Contemporary Lane Towns, etc..." >
                          </a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="Frontages">
                          <a-select mode="tags" :key="type.name" v-model="type.sizes" @change="handleEditSizes(type,$event)" style="width: 100%" placeholder="Enter new frontage sizes ...">
                          </a-select>
                      </a-form-item>
                    </a-col>
             
                </a-row>
    
        </template>
        <template>
                  <a-row :gutter="16" v-for="(type,typeI) in unitTypes" :key="`${typeI}UnitTypes`">
                    <a-col :span="12">
                    <a-form-item label="Unit Type">
                        <a-input v-model="type.name" placeholder="Singles, Towns, Contemporary Lane Towns, etc..." >
                        </a-input>
                    </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="Enter your Frontages">
                          <a-select mode="tags" v-model="type.sizes" style="width: 100%" placeholder="Frontages">
                          </a-select>
                      </a-form-item>
                    </a-col>
             
                </a-row>
                <hr />
                <a-button type="primary" icon="plus" @click="addAnother">Add Another Unit Type</a-button>
    
        </template>
       

      </a-form>
      <div
        :style="{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }"
      >
        <a-button :style="{marginRight: '8px'}" @click="onClose" v-if="dialog.type !== 'add'">
          Cancel
        </a-button>
        <a-button @click="submit" type="primary">{{dialog.type === 'add'? 'Submit' : 'Update Unit Types'}}</a-button>
      </div>
    </a-drawer>
</template>

<script>
  import {markerIcon} from 'bh-mod'
  import bhLoading from 'bh-mod/components/common/Loading'
  import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
  export default {
    components:{bhLoading,ImageBoxSelector},
    data() {
      return {
        editTypes:[],
        original:[],
        loading:false,
        unitTypes:[
            {
                name:'',
                sizes:[],
                newSizes:[]
            }
        ],
        
      }
    },
    watch:{
      dialog:{
        handler(val){
          console.log("DIALOGGGGGGGG", val)
          if (!val.show){
            this.editTypes = []
            // this.editTypes = {
            //   delete:[],
            //   rename:{},
            //   add:[]
            // }
            this.unitTypes = [
              {
                name:'',
                sizes:[]
              }
            ]
          } else if (val.type === 'edit'){
            let types = this.$store.state.models.unitTypes
            let obj = {}

            types.forEach(x => {
              let idName = x.name.trim()
              if (!obj[idName]) 
                obj[idName] = {name:x.name,sizes:[],sizesObjs:[],newSizes:[]}
              obj[idName].sizesObjs.push({size:x.size + '',id:x.id, showDelete:false})
              obj[idName].sizes.push(x.size + '')
            })
            this.unitTypes = []
            this.editTypes = obj
            this.original = JSON.parse(JSON.stringify(this.editTypes))

          }
        },
        deep:true,
      }
    },
    computed:{
        instance(){
          return this.$store.state.instance
        },
        appData(){
          return this.$store.state.appData
        },
        getTitle(){
          return `Create a new `
        },
        dialog(){
          return this.$store.state.models.unitTypeDialog
        },
        unitTags(){
            return [
                {id:123123123,name:'36'},
                {id:1231231232,name:'50'},
                {id:123123,name:'45'},
            ]
        }
    },
    methods: {
        submit(){
          if (this.dialog.type === 'add') return this.makeUnitTypes()
          this.updateUnitTypes()
        },
        updateUnitTypes(){
          let sendObj = {
            delete:[],
            rename:{},
            add:JSON.parse(JSON.stringify(this.unitTypes))
          }

          Object.entries(this.editTypes).forEach( ([key,value]) => {
            
            if (key !== value.name) sendObj.rename[key] = value.name

            let deletes = value.sizesObjs.filter(x => {
              console.log('XXXXXXXX', x.size)
              if (!value.sizes.includes(x.size)){
                return true
              }
            }).map(x => x.id)

            let adding = value.sizes.filter(size => {
              if (!value.sizesObjs.find(sizeobj => sizeobj.size === size)){
                return true
              }
            })
            if (adding.length){
              sendObj.add.push({
                name:value.name,
                sizes:adding
              })
            }
            
            sendObj.delete = [...sendObj.delete, ...deletes]

          })

          console.log('DELETEEEEEEEEEE', sendObj)
          

          let names = []
          let error = false

          this.unitTypes.forEach( x => {
              let name = x.name.trim().toLowerCase()
              if (!name){
                  console.log('FOUND NAMES', x.name)
                  error = 'emptyName'
              } else if (names.includes(name) ){
                  console.log('FOUND NAMES', x.name)
                  error = 'duplicate'
              } else if (!x.sizes.length){
                  error = 'frontage'
              }
              names.push(name)

          })

          if (error){
              return this.$message.error(error.includes('dup')? 'Duplicate Name detected!' : error.includes('Name')? 'Name cannot be Empty' :'Make sure Frontages are not Empty!')
          }

           

          this.$api.put(`/unitTypes/${this.instance.id}`, sendObj).then( ({data}) => {
              console.log('DATAAAAAAAAAAA', data)
              this.onClose({type:'unitTypes--edit',data}, true)
              
          }).catch(err => {
              this.$message.error(this.$err(err))
          })

        },
        handleEditSizes(type,e){
          let orderE = e.sort()
          e = orderE
          console.log('TYPEEEEEE', type)
          console.log('TYPEEEEEE EVENT => ', e)
        },
        deleteSize(type,id){
          type.sizes = type.sizes.filter(x => x.id !== id)
        },
        confirmDelete(e){
          console.log('CONFIRM DELETE', e)
        },
        makeUnitTypes(){
            let names = []
            let error = false
            this.unitTypes.forEach( x => {
                let name = x.name.trim().toLowerCase()
                if (!name){
                    console.log('FOUND NAMES', x.name)
                    error = 'emptyName'
                } else if (names.includes(name) ){
                    console.log('FOUND NAMES', x.name)
                    error = 'duplicate'
                } else if (!x.sizes.length){
                    error = 'frontage'
                }
                names.push(name)

            })

            console.log('NAMESSSSS', names)
            console.log('ERROR', error)
            if (error){
                return this.$message.error(error.includes('dup')? 'Duplicate Name!' : error.includes('Name')? 'Name cannot be Empty' :'Make sure Frontages are not Empty!')
            }
            this.$api.post(`/unitTypes/${this.instance.id}`, this.unitTypes).then( ({data}) => {
                console.log('DATAAAAAAAAAAA', data)
                this.onClose({type:'unitTypes',data}, true)
            }).catch(err => {
                this.$message.error(this.$err(err))
            })

        },
        addAnother(){
            this.unitTypes.push({
                name:'',
                sizes:[]
            })
        },
        onClose({type='',data={}}, okay = false) {
          if (this.dialog.type === 'add' && !okay) return 
          this.$store.dispatch('closeDialog',{type,data})
        },
    },
  };
</script>
