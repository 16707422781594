<template>
        <a-card :title="null" class="cursor-pointer purple-hover relative elevation-card" hoverable :bodyStyle="{padding:0}">
            <a-icon type="edit" class="p-3 bg-white rounded-full edit-elevation" />
            <div class="image-holder" :style="{backgroundImage:`url(${elev.image})`}" />
            <div class="dF fC aC p-3">
                <div>
                    {{elev.name}}
                </div>
                <div>
                    {{elev.packages[0].beds || 0}} <span class="text-med-gray">Beds</span>, {{elev.packages[0].baths || 0}} <span class="text-med-gray">Baths</span> , {{elev.packages[0].sqft || 0}} <span class="text-med-gray">Sqft</span>
                </div>

            </div>
        </a-card>
</template>

<script>
export default {
    props:['elev'],
    computed:{
        elevation(){
            let elev = this.elev
            let beds = []
            let baths = []
            let p
        }
    }
}
</script>

<style scoped lang="scss">
    .ant-card-hoverable:hover{
        /* box-shadow:0 2px 8px rgba(207, 206, 223, .2); */
    }
    .elevation-card{
        .image-holder{
            height:0;
            width:100%;
            padding-bottom:50%;
            position:relative;
            background-size:contain;
            background-repeat:no-repeat;
            background-position: center;
            background-color:var(--off-white-dark);
        }
        .edit-elevation{
            opacity:0;
            transform:translate3d(0,10px,0);
            position:absolute;
            top:10px;
            right:10px;
            z-index:10;
            transition:transform .3s ease-out, opacity .3s ease-out;
        }
        &:hover{
            .edit-elevation{
                opacity:1;
                transform:translate3d(0,0,0);
            }
        }
    }
</style>
